jQuery.fn.exists = function(){return this.length>0;};

function date_heure(id) {
         date = new Date;
         annee = date.getFullYear();
         moi = date.getMonth();
         mois = new Array('Janvier', 'F&eacute;vrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Ao&ucirc;t', 'Septembre', 'Octobre', 'Novembre', 'D&eacute;cembre');
         j = date.getDate();
         jour = date.getDay();
         jours = new Array('Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi');
         h = date.getHours();
         if(h<10) {
            h = "0"+h;
         }
         m = date.getMinutes();
         if(m<10) {
            m = "0"+m;
         }
         s = date.getSeconds();
         if(s<10) {
            s = "0"+s;
         }
         resultat = '<i class="fa fa-calendar" aria-hidden="true"></i>&nbsp;'+j+' '+mois[moi]+' '+annee+'<span class="sep">|</span><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;'  +h+':'+m+':'+s;
         document.getElementById(id).innerHTML = resultat;
         setTimeout('date_heure("'+id+'");','1000');
         return true;
      }

(function($) {

   var firstload = true;

   $(window).on("resize", function () {

   }).resize();

   $(window).bind('scroll', function () {

   });

   $(document).ready(function () {

      $('#soutienspopup').popup();

      $('.owl-carousel').owlCarousel({
         loop:true,
         margin:10,
         nav:true,
         responsive:{
            0:{
               items:1
            },
            600:{
               items:3
            },
            1000:{
               items:3
            }
         }
      });

      date_heure('date_heure');

      $candidatimage = $('.candidats');

      $candidatimage.hover(
         function() {
            $('.candidat_content' ,this).addClass('hover');
         }, function() {
            $('.candidat_content' ,this).removeClass('hover');
         }
      );

      var filterList = {
         init: function () {
            // MixItUp plugin
            // http://mixitup.io
            $('.candidats-list').mixItUp({
               selectors: {
              target: '.candidats',
              filter: '.filter'
            }
         });
         }
      };
      // Run the show!
      filterList.init();

      $( '.filters .filter' ).first().addClass( 'active' );

      setTimeout( function(){
         $candidatimage.each(function() {
            var heighttarget = parseInt($('.candidat_infos' ,this).outerHeight(true), 10);
            $('.candidat_content' ,this).css('bottom', -heighttarget-1);
         });
      } ,500);

      $('.nav_menu_principal .elections').first().addClass('isthefirstelections');
      $('.menu_responsive .elections').first().addClass('isthefirstelections');


      $('footer .widgetactus .actus_item').each(function(index, el) {
         if (index > 2) {
            $(this).css('display', 'none');
         }
      });



      $('nav.menu_responsive ul.sub-menu li a').each(function(index, item) {
         $(this).html('- ' + $(this).html());
      });

      $("<select class='dropdown'  />").appendTo("nav.menu_responsive");

      var temp;

      $("nav.menu_responsive li").each(function() {
         var li = $(this);
         var a = $('a', this);

         if ( li.hasClass('current-menu-item') ) {
            temp = a.attr('href');
         }

         $('<option />', {
            'value'   : a.attr('href'),
            'text'    : a.html(),
            'data-class' : li.attr('class'),
         }).appendTo('nav.menu_responsive select');

      });

      $('nav.menu_responsive option[value="'+temp+'"]').attr("selected", true);


      $("nav.menu_responsive select").change(function() {
         window.location = $(this).find("option:selected").val();
      });

      $('nav.menu_responsive .menu-principal-container').remove();

      var $selects = $('.dropdown');

      $selects.easyDropDown({
         cutOff: 10,
         wrapperClass: 'dropdown',
         onChange: function(selected){
            // do something
         }
      });

      $('.autoheight').matchHeight();
      $('.autoheightun').matchHeight();
      $('.autoheightdeux').matchHeight();



   });



})(jQuery);

